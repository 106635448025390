import { FORM_SUBMIT, FORM_SUCCESS, FORM_FAIL } from "../actions/actionTypes";

const initialState = {
  sendingSubscription: false,
  sentSubscription: false,
  subscribed: false,
  formMsg: null,
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_SUBMIT:
      return {
        ...state,
        sendingSubscription: true,
      };
    case FORM_SUCCESS:
      return {
        ...state,
        sendingSubscription: false,
        sentSubscription: true,
        subscribed: true,
        formMsg: action.msg,
      };
    case FORM_FAIL:
      return {
        ...state,
        sendingSubscription: false,
        sentSubscription: true,
        subscribed: false,
        formMsg: action.msg,
      };
    default:
      return state;
  }
};
